<template>
  <div>
    <tagby-filter
      :category-options="categoryOptions"
      :initial-search-params="initialSearchParams"
      :lw="3"
      @search="search"
    >
      <template #buttons>
        <b-button
          variant="outline-secondary"
          class="mr-1"
          @click="turnOnSidebar"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </b-button>
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <!-- <template #cell(idx)="data">
        <b-link
          :to="{ name: 'update-content-detail', params: { idx: data.value } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.value }}
        </b-link>
      </template> -->
      <template #cell(generated_url)="data">
        <a
          :href="resolveGeneratedUrl(data.item.shorten_url.generated_url)"
          target="_blank"
        >
          {{ resolveGeneratedUrl(data.item.shorten_url.generated_url) }}
        </a>
      </template>

      <template #cell(destination_url)="data">
        {{ data.item.shorten_url.origin_url }}
      </template>

      <template #cell(created_at)="data">
        <div style="width: 90px">
          {{ resolveDatetime(data.item.created_at) }}
        </div>
      </template>
    </tagby-list>

    <add-new-sidebar
      :visible="visibleSidebar"
      :platform="platform"
      :url="url"
      :platform-key="key"
      :reason="reason"
      :is-saving="isSaving"
      :is-valid="isValid"
      @ok="create"
      @hidden="turnOffSidebar"
      @input-platform="inputPlatform"
      @input-key="inputKey"
      @input-url="inputUrl"
      @input-reason="inputReason"
    />
  </div>
</template>

<script>
import {
  BLink,
  BButton,
} from 'bootstrap-vue'
import moment from 'moment'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import AddNewSidebar from './components/AddNewSidebar.vue'

export default {
  components: {
    BLink,
    BButton,
    TagbyFilter,
    TagbyList,
    AddNewSidebar,
  },
  setup() {
    const resolveGeneratedUrl = generatedUrl => `${process.env.VUE_APP_FRONT_SERVER}/redirect/${generatedUrl}`
    const resolveDatetime = datetime => (moment(datetime).isValid() ? moment(datetime).format('YYYY-MM-DD HH:mm:ss') : null)
    return {
      resolveGeneratedUrl,
      resolveDatetime,
      ...viewModel(),
    }
  },
}
</script>
