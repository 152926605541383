<template>
  <tagby-sidebar
    :visible="visible"
    width="30%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      Add New
    </template>
    <template #content>
      <b-row>
        <b-col>
          <b-form-group
            label="PLATFORM"
          >
            <v-select
              :value="platform"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="platformOptions"
              @input="$emit('input-platform', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="KEY"
          >
            <b-form-input
              :value="platformKey"
              @input="$emit('input-key', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="URL"
          >
            <b-form-input
              :value="url"
              @input="$emit('input-url', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="메모"
          >
            <b-form-textarea
              :value="reason"
              @input="$emit('input-reason', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="outline-primary"
            class="mr-1"
            :disabled="!isValid"
            @click="$emit('ok')"
          >
            <b-spinner
              v-if="isSaving"
              small
            />
            확인
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="$emit('hidden')"
          >
            취소
          </b-button>
        </b-col>
      </b-row>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
    vSelect,

    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    platform: {
      type: String,
      default: '',
    },
    platformKey: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    reason: {
      type: String,
      default: '',
    },
  },
  setup() {
    const platformOptions = [
      'FACEBOOK',
      'GOOGLE',
      'KAKAO',
    ]
    return {
      platformOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
