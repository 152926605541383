import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import storeModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-tracking-code-list'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })
  const routerQuery = router.history.current.query
  const initialSearchParams = Object
    .keys(routerQuery)
    .reduce((previoutValue, currentValue) => {
      if (['page', 'size', 'total'].includes(currentValue)) {
        return previoutValue
      }
      if (currentValue === null || currentValue === '') {
        return previoutValue
      }
      if (typeof routerQuery[currentValue] === 'string') {
        return {
          ...previoutValue,
          [currentValue]: [routerQuery[currentValue]],
        }
      }
      return {
        ...previoutValue,
        [currentValue]: routerQuery[currentValue],
      }
    },
    {})
  const searchParams = ref({
    page: parseInt(routerQuery.page, 10) || 1,
    size: parseInt(routerQuery.size, 10) || 20,
    total: parseInt(routerQuery.total, 10) || 0,
    ...initialSearchParams,
  })

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => {})
  }

  const categoryOptions = [
    {
      label: 'GENERATED_URL',
      value: 'GENERATED_URL',
      paramName: 'generated_url',
    },
    {
      label: 'DESTINATION_URL',
      value: 'destination_url',
      paramName: 'origin_url',
    },
  ]

  const tableColumns = [
    { key: 'idx' },
    { key: 'platform' },
    { key: 'key' },
    { key: 'generated_url' },
    { key: 'destination_url' },
    { key: 'state' },
    { key: 'created_at' },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const currentPage = computed(() => parseInt(searchParams.value.page, 10))
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchItems`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 가져오는데 실패했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const search = params => {
    Object.assign(searchParams.value, { ...params, page: 1 })
    fetchItems()
  }

  const changePage = params => {
    Object.assign(searchParams.value, params)
    fetchItems()
  }

  const visibleSidebar = ref(false)
  const platform = ref('FACEBOOK')
  const key = ref()
  const url = ref()
  const reason = ref()
  const turnOnSidebar = () => {
    visibleSidebar.value = true
    platform.value = 'FACEBOOK'
    key.value = null
    url.value = null
  }
  const turnOffSidebar = () => {
    visibleSidebar.value = false
  }
  const inputPlatform = value => {
    platform.value = value
  }
  const inputKey = value => {
    key.value = value
  }
  const inputUrl = value => {
    url.value = value
  }
  const inputReason = value => {
    reason.value = value
  }
  const isSaving = ref(false)
  const isValid = computed(() => Boolean(platform.value) && Boolean(key.value) && Boolean(url.value))

  const create = () => {
    isSaving.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/create`,
      {
        platform: platform.value,
        key: key.value,
        url: url.value,
        reason: reason.value,
      })
      .then(() => {
        makeToast('primary', '생성에 성공 했습니다')
        turnOffSidebar()
        fetchItems()
      }).catch(() => {
        makeToast('danger', '생성에 실패 했습니다')
      }).finally(() => {
        isSaving.value = false
      })
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })
  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    visibleSidebar,
    platform,
    key,
    url,
    reason,
    turnOnSidebar,
    turnOffSidebar,
    inputPlatform,
    inputKey,
    inputUrl,
    inputReason,
    isSaving,
    isValid,
    create,
  }
}
